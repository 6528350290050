<template>
  <el-form :model="orderCommissionForm" ref="orderCommissionForm" :validate-on-rule-change="false">
    <div class="basic-card-block margin-top-0">
      <h1>Commission</h1>
      <div>
        <div class="div-frac-13">
          <el-form-item v-if="tradeType === ORDER_TRADE_TYPE.SALES" label="Broker Company" prop="brokerSysOrganizationId" :rules="rules.reqRule">
            <el-select
              v-model="orderCommissionForm.brokerSysOrganizationId"
              filterable clearable value-key="id"
              @change="handleBrokerCompanySelect"
              :disabled="propsView === 'VIEW_ORDER'"
            >
              <el-option
                v-for="item in sortByAlphabetical(creditAssociationList, 'counterpartyCompanyName')"
                :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                :disabled="item.status !== 'ACTIVE'"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="Broker Company">
            <el-input v-model="orderCommissionForm.brokerCompanyName" disabled/>
          </el-form-item>
          <el-form-item v-if="tradeType === ORDER_TRADE_TYPE.SALES" label="Broker Person-In-Charge" prop="brokerId">
            <el-select
              v-model="orderCommissionForm.brokerId"
              filterable clearable value-key="id"
              :disabled="propsView === 'VIEW_ORDER'"
            >
              <el-option
                v-for="item in sortByAlphabetical(customerList, 'name')"
                :key="item.id" :label="item.name" :value="item.id"
                :disabled="!item.isActive && !creditAssociation.isOnlineCounterparty"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="Broker Person-In-Charge">
            <el-input v-model="orderCommissionForm.brokerName" disabled/>
          </el-form-item>
        </div>
        <div class="div-product-other-costs-info-body">
          <el-button
            type="primary" round size="mini" style="margin-bottom: 10px;"
            @click="addCommissionProduct" :disabled="propsView === 'VIEW_ORDER'"
          >
            Add Commission
          </el-button>
          <el-table :data="orderCommissionForm.commissions" border>
            <el-table-column label="Product Name" min-width="350px">
              <template v-slot="scope">
                <el-form-item :prop="`commissions.${scope.$index}.orderProductId`">
                  <el-select v-model="scope.row.orderProductId" :disabled="propsView === 'VIEW_ORDER' || scope.row.status === 'INVOICE_CREATED'" clearable @change="handleOrderCommProductNameSelect($event, scope.$index)">
                    <el-option
                      v-for="item in commissionProductList"
                      :key="item.id" :label="`${item.sysProductName} (${item.currency} ${numberWithCommas(item.price, 2)})`" :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="150px">
              <template v-slot:header><span>* </span>Fee</template>
              <template v-slot="scope">
                <el-form-item :prop="`commissions.${scope.$index}.fee`" :rules="rules.reqRule">
                  <el-input-number
                    v-model="scope.row.fee"
                    :controls="false" :precision="2" :min="null"
                    :disabled="propsView === 'VIEW_ORDER' || scope.row.status === 'INVOICE_CREATED'"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="120px">
              <template v-slot:header><span>* </span>Fee Currency</template>
              <template v-slot="scope">
                <el-form-item :prop="`commissions.${scope.$index}.currency`" :rules="rules.reqRule">
                  <el-select v-model="scope.row.currency" disabled>
                    <el-option
                      v-for="item in currencyList"
                      :key="item.id" :label="item.code" :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column min-width="150px">
              <template v-slot:header><span>* </span>Fee UOM Type</template>
              <template v-slot="scope">
                <el-form-item :prop="`commissions.${scope.$index}.uomType`" :rules="rules.reqRule">
                  <el-select v-model="scope.row.uomType" :disabled="propsView === 'VIEW_ORDER' || !scope.row.orderProductId || scope.row.status === 'INVOICE_CREATED'">
                    <el-option
                      v-for="item in ORDER_COMM_UOM_TYPE_OPTIONS"
                      :key="item.value" :label="item.label" :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Amount" min-width="150px">
              <template v-slot="scope">
                <el-form-item>
                  <el-input-number
                    v-model="scope.row.amount"
                    :controls="false" :precision="2" :min="null"
                    disabled
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Display" style="text-align: center;" min-width="80px" v-if="tradeType === ORDER_TRADE_TYPE.SALES">
              <template v-slot="scope">
                <el-switch v-model="scope.row.isDisplayCommission" style="margin-bottom: 22px;" :disabled="propsView === 'VIEW_ORDER'"/>
              </template>
            </el-table-column>
            <el-table-column width="50px" fixed="right">
              <template v-slot="scope">
                <div>
                  <el-button v-if="scope.row.status !== 'INVOICE_CREATED' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle @click="removeCommissionProduct(scope.$index)" :disabled="propsView === 'VIEW_ORDER'"/>
                  <el-tooltip v-else class="item" effect="dark" content="Invoice Created" placement="top">
                    <el-button class="el-icon-check" type="success" size="mini" circle/>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-form>
</template>
<script>
import { ORDER_COMM_UOM_TYPE_OPTIONS, ORDER_TRADE_TYPE } from '@/constants.js'
import { numberWithCommas, sortByAlphabetical } from '@/utils/index.js'
export default {
  name: 'OrderCommissionForm',
  props: {
    currencyList: Array,
    creditAssociationList: Array,
    orderProductList: Array,
    productList: Array,
    propsView: String,
    orderCommission: Object,
    tradeType: String,
    resetOrderComm: Boolean
  },
  data () {
    return {
      ORDER_TRADE_TYPE,
      ORDER_COMM_UOM_TYPE_OPTIONS,
      rules: {
        reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]
      },
      orderCommissionForm: {
        brokerSysOrganizationId: null,
        brokerCompanyName: null,
        brokerId: null,
        brokerName: null,
        commissions: []
      },
      commissionProduct: {
        orderProductId: null,
        fee: null,
        currency: 'USD',
        uomType: 'LUMPSUM',
        amount: null,
        isDisplayCommission: false
      },
      commissionProductList: [],
      customerList: [],
      creditAssociation: null
    }
  },
  watch: {
    resetOrderComm () {
      this.handleBrokerCompanySelect(this.orderCommission.brokerSysOrganizationId)
      this.orderCommissionForm.brokerId = this.orderCommission.brokerId
      this.orderCommissionForm.commissions = this.$_.cloneDeep(this.orderCommission.commissions)
    }
  },
  created () {
    if (this.orderCommission && this.orderCommission.commissions.length > 0) {
      this.handleBrokerCompanySelect(this.orderCommission.brokerSysOrganizationId)
      this.orderCommissionForm = this.$_.cloneDeep(this.orderCommission)
      if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE) {
        this.orderCommissionForm.commissions = this.orderCommissionForm.commissions.filter(d => d.isDisplayCommission)
      }
    }
    this.productList.map(p => {
      if (this.orderProductList) {
        const product = this.orderProductList.filter(op => op.sysProductId === p.id)
        if (product) {
          this.commissionProductList.push(...product)
        }
      }
    })
  },
  methods: {
    numberWithCommas,
    sortByAlphabetical,
    handleOrderCommProductNameSelect (orderProductId, index) {
      if (!orderProductId) {
        this.orderCommissionForm.commissions[index].orderProductId = null
        this.orderCommissionForm.commissions[index].uomType = 'LUMPSUM'
      } else {
        this.orderCommissionForm.commissions[index].uomType = null
      }
    },
    handleBrokerCompanySelect (companyId) {
      this.orderCommissionForm.brokerId = null
      this.creditAssociation = this.creditAssociationList.find(item => item.counterpartySysOrganizationId === companyId) || {}
      this.getUserListByCompany(companyId, this.creditAssociation.isOnlineCounterparty).then(userList => {
        this.customerList = userList
      })
    },
    addCommissionProduct () {
      const product = this.$_.cloneDeep(this.commissionProduct)
      this.orderCommissionForm.commissions.push(product)
    },
    removeCommissionProduct (index) {
      this.orderCommissionForm.commissions.splice(index, 1)
    },
    getUserListByCompany (companyId, isOnline) {
      if (isOnline) {
        return new Promise((resolve, reject) => {
          this.$request.get({
            url: `${this.$apis.sharedUserBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${companyId}`,
          }).then(res => {
            if (res?.code === 1000) {
              const userList = (res?.data?.records || [])
              resolve(userList)
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$request.get({
            url: `${this.$apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId : '${companyId}'&pageNumber=0&pageSize=9999`
          }).then(res => {
            if (res?.code === 1000) {
              const contactList = res.data.content
              resolve(contactList)
            }
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
